<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: ResourceTab
Description:This file contains the details of the resources if added , add the new resources and edit the delete the corresponding resources
-->
<template>
  <el-row v-loading="loading" element-loading-background="white">
    <el-row v-if="!loading">
      <el-col class="tab-resources" :span="24">
        <el-row type="flex" justify="end" class="action-toolbar">
          <el-col :span="4.5">
            <er-input
              inputType="search"
              v-model="searchFeed"
              size="mini"
              suffix-icon="el-icon-search"
              :placeholder="$t('Comn_search')"
            ></er-input>
          </el-col>
          <er-button
            v-show="!loading"
            btnType="circle_add"
            :showLabel="true"
            :showIcon="true"
            :disabled="isReadOnly"
            size="mini"
            @click="
              handleClickAction($event, 'add', $lodash.cloneDeep(newResource))
            "
          >
            <slot>
              <p style="text-transform: uppercase">
                {{ $t("Resource_add_reso") }}
              </p>
            </slot>
          </er-button>
        </el-row>

        <er-data-tables
          ref="resourceTable"
          uniqueId="resource__tab__table"
          :actionColumn="false"
          :columns="tableColumns"
          :tableData="resources"
          type="white-header-table"
          :elTableProps="tableProps"
          :page-size="$constants.table['page-size']"
          :pagination-props="$constants.table['pagination-props']"
          :key="$i18n.locale"
          :searchOnTableData="searchFeed"
        >
          <template v-slot:name="{ row }">
            {{
              row.data.type === "FEED" && row.data.feed_type
                ? `${row.data.name} - ${row.data.feed_type} `
                : row.data.name
            }}
          </template>
          <template v-slot:kg_dispensed_time="{ row }">
            {{ ftm__formatEmpty(row.data.kg_dispensed_time) }}
          </template>
          <template v-slot:units="{ row }">
            <span class="units-cell">
              {{ ftm__formatEmpty(row.data.units.toLowerCase()) }}
            </span>
          </template>
          <template v-slot:type="{ row }">
            {{ ftm__formatEmpty(resourceTypes[row.data.type], true) }}
          </template>
          <template v-slot:status="{ row }">
            <er-button
              :showLabel="true"
              :showIcon="true"
              type="text"
              size="mini"
              btnType="edit"
              :disabled="isReadOnly"
              @click="
                handleClickAction($event, 'edit', $lodash.cloneDeep(row.data))
              "
            ></er-button>
            <el-divider direction="vertical"></el-divider>
            <er-button
              :showLabel="true"
              :showIcon="true"
              type="text"
              size="mini"
              btnType="delete"
              :disabled="isReadOnly"
              @click="handleRemoveResource($event, row.data)"
            ></er-button>
          </template>
        </er-data-tables>

        <component
          :is="`resourceActionDialog`"
          :showDialog="dialogVisible"
          :resource="selectedResource"
          :actionToPerform="actionToPerform"
          @close_dialog="closeDialog"
        ></component>
      </el-col>
    </el-row>
    <Loader v-else-if="loading"></Loader>
  </el-row>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import resourceActionDialog from "./resourceActionDialog";
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import notificationMixin from "@/mixins/notificationMixin";
import Loader from "../base/Loader";

export default {
  name: "resourceTab",
  props: ["tabData"],
  mixins: [errorHandlerMixin, filtersMixin, notificationMixin],
  components: {
    resourceActionDialog,
    Loader
  },
  data: function () {
    return {
      dialogVisible: false,
      selectedResource: {},
      loading: false,
      searchFeed: "",
      newResource: {
        type: "",
        name: "",
        feed_type: "",
        kg_dispensed_time: 24,
        units: ""
      },
      tableProps: {
        align: "center",
        height: "calc(100vh - var(--table-gutter))"
      },
      actionToPerform: "",
      actionComponentName: "",
      params: {
        get_all: true,
        order_by: "name",
        order_type: "asc",
        type: null
      },
      errorCodes: {
        RESOURCE_IN_USE_CANT_BE_DELETED: "Comn_resource_can_be_deleted"
      }
    };
  },

  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),

    tableColumns() {
      return {
        name: {
          prop: "name",
          label: this.$t("Resource_name"),
          sortable: "custom",
          enableSearch: true,
          searchProperty: "name"
        },
        type: {
          prop: "type",
          label: this.$t("Comn_type"),
          sortable: "custom",
          enableSearch: true,
          searchProperty: "type"
        },
        units: {
          prop: "units",
          label: this.$t("Comn_units"),
          sortable: "custom",
          enableSearch: true,
          searchProperty: "units"
        },
        kg_dispensed_time: {
          prop: "kg_dispensed_time",
          label: this.$t("PM_kg_disp_time"),
          sortable: "custom",
          enableSearch: true,
          searchProperty: "kg_dispensed_time"
        },
        status: {
          prop: "status",
          label: this.$t("Comn_actions"),
          sortable: "custom"
        }
      };
    },
    resources() {
      return this.$store.getters["resource/getArrResources"];
    },
    resourceTypes() {
      return {
        FEED: this.$t("Comn_feed"),
        MEDICINE: this.$t("Comn_stock_medicine"),
        MINERAL: this.$t("Comn_mineral"),
        PRO_BIOTIC: this.$t("Medicine_pro_biotic"),
         DO_SUBSTITUTE: this.$t("Comn_stock_do_substitute"),
        WATER_CONDITIONER: this.$t("Comn_stock_water_conditioner"),
        CARBON_SOURCE: this.$t("Comn_stock_carbon_source"),
        DISINFECTANT: this.$t("Comn_stock_disinfectant"),
        FERTILIZER: this.$t("Comn_stock_fertilizer"),
        FEED_ADDITIVES: this.$t("Comn_stock_feed_additives"),
        ORGANIC_ACID: this.$t("Comn_stock_organic_acid")
      };
    }
  },
  async mounted() {
    await this.initComponent();
  },
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async initComponent() {
      this.loading = true;
      try {
        this.params.include = ["name", "type", "units", "kg_dispensed_time", "status", "feed_type"]
        await this.$store.dispatch("resource/fetchAllResources", this.params);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.resourceTable) {
            this.$refs.resourceTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    initData() {
      this.dialogVisible = false;
      this.selectedResource = this.$lodash.cloneDeep(this.newResource);
      this.actionToPerform = "";
    },
    handleClickAction(event, actionToPerform, resourceObj) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.dialogVisible = true;
        this.loading = true;
        this.actionToPerform = actionToPerform;
        this.selectedResource = resourceObj;
        this.loading = false;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    closeDialog(event) {
      this.dialogVisible = false;
      if (event !== "close") {
        if (this.actionToPerform === 'add') {
          this.mixPanelEventGenerator({ eventName: "Resources - Resources Table - Add Resource - Save" });
        }
        if (this.actionToPerform === 'edit') {
          this.mixPanelEventGenerator({ eventName: "Resources - Resources Table - Edit Resource - Save" });
        }
        this.initComponent();
      }
      this.initData();
    },
    async handleRemoveResource(event, resource) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation(resource.name, this.resourceTypes[resource.type]);
        this.loading = true;
        await this.$store.dispatch("resource/deleteResource", resource._id);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Resource_deletion_successfully"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({ eventName: "Resources - Resources Table - Delete" });
        await this.initComponent();
      } catch (err) {
        const errors = [];

        if (err.response && err.response.data.message) {
          if (this.errorCodes[err.response.data.error_code]) {
            errors.push({
              message: this.$t(this.errorCodes[err.response.data.error_code])
            });
          } else {
            errors.push({ message: err.response.data.message });
          }

          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    ehm__error409Handler: function (err) {
      console.log(err.response.data.message);
      this.ehm__errorFailToSave([
        {
          message: this.$t("resource_in_use_cant_be_deleted")
        }
      ]);
    },
    ehm__error422Handler: function (err) {
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        err.response.data.errors.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'

          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") + el.message + ",";
        });
        // this.ehm__unhandledErrorMessage = err.response.data.errors;
      } else {
        this.ehm__unhandledErrorMessage = err.response.data.message;
      }
    }
  }
};
</script>

<style lang="scss">
.tab-resources {
  @include responsiveProperty(--table-gutter, 200px, 210px, 215px);
  .action-toolbar {
    i {
      font-size: 12px;
    }
  }
  .units-cell {
    text-transform: capitalize;
  }

  .action-btn {
    .img {
      display: inline-flex;
    }
    .material-icons-round {
      font-size: 12px;
    }
  }
}
</style>
