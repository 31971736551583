<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: LabActionDialog
Description:This file contains the functionality to add the labs details in the form
-->
<template>
  <er-dialog
    width="35%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @open="handleOpenDialog"
    @beforeClose="handleCloseDialog('close')"
    :title="
      actionToPerform === 'edit'
        ? $t('Resource_edit_lab')
        : $t('Resource_new_lab')
    "
    class="action-on-labs"
    custom-class="labs_action_dialog"
  >
    <el-form size="small" :model="formLab" :inline="true">
      <el-form-item
        :label="formStrings.title.label"
        class="input-name"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.title)"
      >
        <el-input
          v-model="formLab.title"
          :placeholder="formStrings.title.placeholder"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="formStrings.phone.label"
        class="input-feed-type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)"
      >
        <el-input
          v-model="formLab.phone"
          :placeholder="formStrings.phone.placeholder"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="formStrings.technicianname.label"
        class="input-feed-type"
        :error="
          ekmm__castErrorKeyToLang(
            ehm__errMessagesObject.technician_details.name
          )
        "
      >
        <el-input
          v-model="formLab.technician_details.name"
          :placeholder="formStrings.technicianname.placeholder"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="formStrings.technicianphone.label"
        class="input-feed-type"
        :error="
          ekmm__castErrorKeyToLang(
            ehm__errMessagesObject.technician_details.phone
          )
        "
      >
        <el-input
          v-model="formLab.technician_details.phone"
          :placeholder="formStrings.technicianphone.placeholder"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="formStrings.address.label"
        class="input-feed-type"
        :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.address)"
      >
        <el-input
          v-model="formLab.address"
          :placeholder="formStrings.address.placeholder"
        ></el-input>
      </el-form-item>
    </el-form>
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="labDetails()"
        :disabled="isReadOnly"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog('close')"
        :disabled="isReadOnly"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin],
  props: {
    lab: {
      default: () => ({
        title: "",
        phone: "",
        technician_details: {
          name: "",
          phone: ""
        },
        address: ""
      })
    },

    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function() {
    return {
      loading: false,
      formLab: {
        title: "",
        phone: "",
        technician_details: {
          name: "",
          phone: ""
        },
        address: ""
      },
      newErrObject: {
        title: "",
        phone: "",
        technician_details: {
          name: "",
          phone: ""
        },
        address: ""
      },
      ehm__errMessagesObject: {
        title: "",
        phone: "",
        technician_details: {
          name: "",
          phone: ""
        },
        address: ""
      },
      ekmm__backndfieldToFieldNameMap: {
        title: "Resource_lab_name",
        phone: "Comn_mobile_num",
        name: "Resource_technician_name",
        address: "Comn_address"
      },
      ekmm__backndfieldToInvalidMap: {
        name: {
          FIELD_REQUIRED: "Comn_name",
          NAME_ALREADY_TAKEN: "Lab_technician_name_already_exists"
        },
        title: {
          FIELD_REQUIRED: "Resource_lab_name",
          NAME_ALREADY_TAKEN: "Lab_name_already_exists"
        },
        phone: {
          FIELD_REQUIRED: "Comn_mobile_num",
          PHONE_NUMBER_ALREADY_TAKEN: "Usrs_phone_num_already_in_use",
          Invalid_phone_number: "Invalid_phone_number"
        },
        address: {
          FIELD_REQUIRED: "Comn_address",
          ADDRESS_ALREADY_TAKEN: "address_already_in_use"
        }
      }
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    ...mapGetters("resource", {
      getLabs: "getArrLabs"
    }),

    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    formStrings() {
      return {
        phone: {
          label: this.$t("Resource_lab_phone"),
          placeholder: this.$t("Resource_enter_lab_phone")
        },
        title: {
          label: this.$t("Resource_lab_name"),
          placeholder: this.$t("Resource_enter_lab_name")
        },
        technicianname: {
          label: this.$t("Resource_technician_name"),
          placeholder: this.$t("Resource_enter_technicain_name")
        },
        technicianphone: {
          label: this.$t("Resource_technician_phone"),
          placeholder: this.$t("Resource_technician_phone_num")
        },
        address: {
          label: this.$t("Resource_lab_address"),
          placeholder: this.$t("Resource_enter_lab_addr")
        }
      };
    }
  },
  methods: {
    handleOpenDialog() {
      this.formLab = this.lab;
    },
    handleCloseDialog(event) {
      this.$emit("close_dialog", event);
      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);
    },
    async labDetails() {
      const labtitles = this.getLabs;

      // const labphones = this.getLabs;

      // const techniciannames = this.getLabs;

      // const technicianphones = this.getLabs;

      // const labaddress = this.getLabs;

      this.formLab.phone = this.formLab.phone.trim();
      this.formLab.title = this.formLab.title.trim();
      this.formLab.address = this.formLab.address.trim();
      this.formLab.technician_details.name = this.formLab.technician_details.name.trim();
      this.formLab.technician_details.phone = this.formLab.technician_details.phone.trim();

      const titleValue = (acc, x) => {
        if (this.actionToPerform === "edit" && x._id === this.formLab._id) {
          return acc;
        }
        acc.push(
          ["title"].map((key) => x[key].toLowerCase().trim()).join("__")
        );
        return acc;
      };
      const existingLabTitles = new Set(labtitles.reduce(titleValue, []));
      const isTitleAlreadyTaken = existingLabTitles.has(
        ["title"]
          .map((key) => this.formLab[key].toLowerCase().trim())
          .join("__")
      ); // title Validation

      // const phoneValue = (acc, x) => {
      //   if (this.actionToPerform === "edit" && x._id === this.formLab._id) {
      //     return acc;
      //   }
      //   acc.push(
      //     ["phone"].map((key) => x[key].toLowerCase().trim()).join("__")
      //   );
      //   return acc;
      // };
      // const existingLabPhones = new Set(labphones.reduce(phoneValue, []));
      // const isPhoneAlreadyTaken = existingLabPhones.has(
      //   ["phone"]
      //     .map((key) => this.formLab[key].toLowerCase().trim())
      //     .join("__")
      // ); // phone validation

      // const addressFn = (acc, x) => {
      //   if (this.actionToPerform === "edit" && x._id === this.formLab._id) {
      //     return acc;
      //   }
      //   acc.push(
      //     ["address"].map((key) => x[key].toLowerCase().trim()).join("__")
      //   );
      //   return acc;
      // };
      // const existingaddress = new Set(labaddress.reduce(addressFn, []));
      // const isAddressAlreadyTaken = existingaddress.has(
      //   ["address"]
      //     .map((key) => this.formLab[key].toLowerCase().trim())
      //     .join("__")
      // ); // address validation

      // const techPhFn = (acc, x) => {
      //   if (this.actionToPerform === "edit" && x._id === this.formLab._id) {
      //     return acc;
      //   }
      //   acc.push(
      //     ["technician_details"]
      //       .map((key) => x[key].phone.toLowerCase().trim())
      //       .join("__")
      //   );
      //   return acc;
      // };
      // const existingTechPhone = new Set(technicianphones.reduce(techPhFn, []));
      // const istechPhoneAlreadyTaken = existingTechPhone.has(
      //   ["technician_details"]
      //     .map((key) => this.formLab[key].phone.toLowerCase().trim())
      //     .join("__")
      // ); // technician phone validation

      // const techNameFn = (acc, x) => {
      //   if (this.actionToPerform === "edit" && x._id === this.formLab._id) {
      //     return acc;
      //   }
      //   acc.push(
      //     ["technician_details"]
      //       .map((key) => x[key].name.toLowerCase().trim())
      //       .join("__")
      //   );
      //   return acc;
      // };
      // const existingTechName = new Set(techniciannames.reduce(techNameFn, []));
      // const istechNameAlreadyTaken = existingTechName.has(
      //   ["technician_details"]
      //     .map((key) => this.formLab[key].name.toLowerCase().trim())
      //     .join("__")
      // ); // technician name validation

      this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject); // this is main thing

      if (
        (this.formLab.title === "" &&
          this.formLab.address === "" &&
          this.formLab.phone === "" &&
          this.formLab.technician_details.name === "" &&
          this.formLab.technician_details.phone === "" &&
          this.actionToPerform === "add") ||
        (this.actionToPerform === "edit" &&
          this.formLab.title === "" &&
          this.formLab.address === "" &&
          this.formLab.phone === "" &&
          this.formLab.technician_details.name === "" &&
          this.formLab.technician_details.phone === "")
      ) {
        this.ehm__errMessagesObject.title = {
          error_code: "FIELD_REQUIRED",
          key: "title"
        };
        this.ehm__errMessagesObject.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };
        this.ehm__errMessagesObject.technician_details.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.ehm__errMessagesObject.technician_details.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };
        this.ehm__errMessagesObject.address = {
          error_code: "FIELD_REQUIRED",
          key: "address"
        };
        this.loading = false;
      } else if (
        this.formLab.title === "" &&
        (this.actionToPerform === "add" || "edit")
      ) {
        this.ehm__errMessagesObject.title = {
          error_code: "FIELD_REQUIRED",
          key: "title"
        };
        this.loading = false;
      } else if (isTitleAlreadyTaken) {
        this.ehm__errMessagesObject.title = {
          error_code: "NAME_ALREADY_TAKEN",
          key: "title"
        };

        this.loading = false;
      } else if (
        this.formLab.phone === "" &&
        (this.actionToPerform === "add" || "edit")
      ) {
        this.ehm__errMessagesObject.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };
        this.loading = false;
      } else if (
        this.formLab.technician_details.name === "" &&
        (this.actionToPerform === "add" || "edit")
      ) {
        this.ehm__errMessagesObject.technician_details.name = {
          error_code: "FIELD_REQUIRED",
          key: "name"
        };
        this.loading = false;
      } else if (
        this.formLab.technician_details.phone === "" &&
        (this.actionToPerform === "add" || "edit")
      ) {
        this.ehm__errMessagesObject.technician_details.phone = {
          error_code: "FIELD_REQUIRED",
          key: "phone"
        };
        this.loading = false;
      } else if (
        this.formLab.address === "" &&
        (this.actionToPerform === "add" || "edit")
      ) {
        this.ehm__errMessagesObject.address = {
          error_code: "FIELD_REQUIRED",
          key: "address"
        };
        this.loading = false;
      } else if (isNaN(this.formLab.phone)) {
        this.ehm__errMessagesObject.phone = {
          error_code: "Invalid_phone_number",
          key: "phone"
        };
        this.loading = false;
      } else if (isNaN(this.formLab.technician_details.phone)) {
        this.ehm__errMessagesObject.technician_details.phone = {
          error_code: "Invalid_phone_number",
          key: "phone"
        };
        this.loading = false;
      } else {
        this.formLab.title = this.formLab.title.trim();
        this.formLab.phone = this.formLab.phone.trim();
        this.formLab.address = this.formLab.address.trim();
        this.formLab.technician_details.name = this.formLab.technician_details.name.trim();
        this.formLab.technician_details.phone = this.formLab.technician_details.phone.trim();

        try {
          this.loading = true;
          const storeAction =
            this.actionToPerform === "edit"
              ? "resource/updateLab"
              : "resource/AddLab";
          await this.$store.dispatch(storeAction, this.formLab);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t(
              this.actionToPerform === "add"
                ? "Lab_details_saved_success"
                : "Lab_details_updated_success"
            ),
            duration: 5000,
            type: "success"
          });
          this.handleCloseDialog("lab-action-" + this.actionToPerform);
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.aciton-on-vendor {
  .el-dialog__body {
    padding: 15px 15px;
  }
  .el-input-number {
    width: 100%;
  }
}
.labs_action_dialog {
  background: #fcfcfc;
  border-radius: 10px;
  .el-input--small .el-input__inner {
    font-size: 14px !important;
    @include responsiveProperty(width, 205px, 227px, 250px);
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      15px !important,
      16px !important,
      16px !important
    );
    @include responsiveProperty(width, 230px, 227px, 250px);
  }
}
</style>
